var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable effector/no-watch */
import './cup/init';
import { 
// merge,
sample, forward, createEffect, } from 'effector';
import { 
// cleanSocket,
closed, error, open, $status, onMessage, onMessageReject, onMessageResolve, $authorized, changeAuthorized, send, sendFx, $messages, addMessage, addMessageBase, clearMessages, 
// $state,
// onAuthorized,
playSoundFx, playSound, addSuccessMessage, addErrorMessage, print, printFx, setPrinter, $printer, socket, cleanSocket, printSerializer, printSerializerFx, sendDataToSerializer, sendDataToSerializerFx, } from '.';
import { clearCounters, resetPrinted, resetVerified, setCounter,
// printedInc,
 } from './cup';
import { emit } from './emitter';
import { $token } from '../authenticate';
import { b64toBlob, downloadFile, fetchWithTimeout } from '@alta-m1/helpers';
import { $printersList, $defaultPrinter } from '../settings';
import { fetchFx } from '../fetch';
import { $serializers } from '../settings';
var PrintError = /** @class */ (function (_super) {
    __extends(PrintError, _super);
    function PrintError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = 'PrintError';
        return _this;
    }
    return PrintError;
}(Error));
var awaitingMap = new Map();
sample({
    clock: closed,
    target: [clearCounters, resetPrinted, resetVerified, clearMessages],
});
sendFx.use(function (data) {
    var Operation = data.Operation;
    socket.send(JSON.stringify(data));
    return new Promise(function (resolve, reject) {
        awaitingMap.set(Operation, { resolve: resolve, reject: reject });
    });
});
printFx.use(function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var body, timeout, headers, address, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = params.body, timeout = params.timeout, headers = params.headers, address = params.address;
                if (!address) {
                    return [2 /*return*/, Promise.reject(new PrintError('Incorrect printer name!'))];
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, fetchWithTimeout(address, {
                        headers: headers,
                        method: 'POST',
                        mode: 'cors',
                        body: body,
                    }, timeout)];
            case 2:
                _a.sent();
                return [2 /*return*/, Promise.resolve()];
            case 3:
                e_1 = _a.sent();
                return [2 /*return*/, Promise.reject(new Error((e_1 === null || e_1 === void 0 ? void 0 : e_1.message) || String(e_1)))];
            case 4: return [2 /*return*/];
        }
    });
}); });
sample({
    clock: printFx.failData,
    filter: function (error) {
        return error instanceof PrintError;
    },
    fn: function (error) {
        return error.message;
    },
    target: addErrorMessage,
});
printFx.finally.watch(function (_a) {
    var _b;
    var params = _a.params;
    (_b = params.callback) === null || _b === void 0 ? void 0 : _b.call(params);
    emit('success', params.statistic);
});
sample({
    clock: print,
    source: $printer,
    filter: function (printer) {
        return printer == null;
    },
    fn: function () {
        return 'Укажите принтер для печати!';
    },
    target: addErrorMessage,
});
sample({
    clock: print,
    source: $printer,
    filter: function (printer) {
        return printer != null;
    },
    fn: function (printer, _a) {
        var _b = __read(_a, 3), body = _b[0], callback = _b[1], statistic = _b[2];
        var _c = printer, timeout = _c.timeout, address = _c.address, headers = _c.headers;
        //body - то zpl 
        console.log('INIT PRINT FN', {
            body: body,
            timeout: timeout,
            address: address,
            headers: headers,
            callback: callback,
            statistic: statistic,
        });
        return {
            body: body,
            timeout: timeout,
            address: address,
            headers: headers,
            callback: callback,
            statistic: statistic,
        };
    },
    target: printFx,
});
sample({
    clock: setPrinter,
    source: {
        printers: $printersList,
        defaultPrinter: $defaultPrinter,
    },
    fn: function (_a, id) {
        var printers = _a.printers, defaultPrinter = _a.defaultPrinter;
        if (id) {
            return printers.get(id) || defaultPrinter;
        }
        return defaultPrinter;
    },
    target: $printer,
});
onMessage.watch(function (payload) {
    var id = payload.Operation;
    var promise = awaitingMap.get(id);
    if (payload.Error) {
        // awaitingMap.delete(id);
        promise === null || promise === void 0 ? void 0 : promise.reject(new Error(payload.Error.Message));
        // awaitingMap.delete(id);
    }
    // awaitingMap.delete(id);
    promise === null || promise === void 0 ? void 0 : promise.resolve(payload);
    // awaitingMap.delete(id);
});
/* forward({
    from: closed,
    to: clearCounters,
}); */
$status
    .on(open, function () { return 'open'; })
    .on(closed, function () { return 'closed'; })
    .on(error, function () { return 'error'; });
closed.watch(function () {
    cleanSocket();
    awaitingMap.clear();
});
sample({
    clock: open,
    source: $token,
    filter: function (token) {
        return !!token;
    },
    fn: function (token) {
        return {
            Operation: 'Auth',
            Data: {
                Token: token,
            },
        };
    },
    target: sendFx,
});
$authorized.on(changeAuthorized, function (_, data) { return data; }).reset(closed, error);
sample({
    clock: send,
    source: $authorized,
    filter: function (authorized) {
        return authorized;
    },
    fn: function (_, operation) {
        return operation;
    },
    target: sendFx,
});
sample({
    clock: send,
    source: { status: $status, authorized: $authorized },
    filter: function (_a) {
        var authorized = _a.authorized;
        return !authorized;
    },
    fn: function (_a) {
        var status = _a.status;
        if (status !== 'open') {
            return { type: 'error', message: 'Отсутствует соединение с сервером!' };
        }
        return { type: 'error', message: 'Пользователь не авторизован!' };
    },
    target: addMessage,
});
sample({
    clock: addMessageBase,
    filter: function (_a) {
        var type = _a.type;
        return type != null;
    },
    fn: function (_a) {
        var type = _a.type;
        return type;
    },
    target: playSoundFx,
});
forward({
    from: playSound,
    to: playSoundFx,
});
$messages
    .on(addMessageBase, function (state, message) { return __spreadArray(__spreadArray([], __read(state), false), [message], false); })
    // .on(sendFx.failData, (state, error) => [...state, {type: 'error', message: error.message}])
    .reset(clearMessages);
var showErrorNotificationFx = createEffect(function (message) {
    alert(message);
});
$messages.watch(function (messages) {
    var errorMessage = messages.find(function (_a) {
        var type = _a.type;
        return type === 'error';
    });
    if (errorMessage) {
        showErrorNotificationFx(errorMessage.message);
    }
});
/* sendFx.finally.watch(({ params }) => {
    const id = params.Operation;
    awaitingMap.delete(id);
}); */
sample({
    clock: sendFx.failData,
    target: onMessageReject,
});
sample({
    clock: sendFx.doneData,
    target: onMessageResolve,
});
onMessageReject.watch(function (error) {
    addErrorMessage(error.message);
    emit('error', error.message);
});
onMessageResolve.watch(function (payload) {
    var Operation = payload.Operation, Data = payload.Data, Result = payload.Result, Statistics = payload.Statistics;
    switch (Operation) {
        case 'Auth':
            if (Result === null || Result === void 0 ? void 0 : Result.Result) {
                changeAuthorized(true);
                addSuccessMessage('Authorization successfully!');
            }
            break;
        case 'GetStatistics':
            if (Statistics) {
                // console.log(Statistics);
                var _a = Statistics.ProtocolStatus1, printed = _a === void 0 ? 0 : _a, _b = Statistics.ProtocolStatus2, verified = _b === void 0 ? 0 : _b;
                // printedInc(Math.abs(verified - printed) + 1);
                if (verified) {
                    setCounter(['confirm', verified, undefined]);
                }
                if (printed) {
                    setCounter(['print', printed, undefined]);
                }
                // console.log(self.counter, self.counter1);
            }
            break;
        //********   PrintLabel   **************//
        case 'PrintLabel':
            if (Data) {
                var counterType_1 = Data['Status'] === 2 ? 'confirm' : 'print';
                if (Data['ZPL']) {
                    print([
                        Data['ZPL'],
                        function () {
                            // self.props.playSound('success');
                            addMessage("Data received successfully! Count of labels: ".concat(Data['Count']));
                            setCounter([counterType_1, Data['Count'], undefined]);
                            // self.emit();
                        },
                        Data['Statistic'],
                    ]);
                }
                if (Data['PDF']) {
                    var usePrint = window.confirm('Use a printer?');
                    if (usePrint) {
                        print([
                            Data['PDF'],
                            function () {
                                // self.props.playSound('success');
                                addMessage("Data received successfully! Count of labels: ".concat(Data['Count']));
                                setCounter([counterType_1, Data['Count'], undefined]);
                                // self.emit();
                            },
                            Data['Statistic'],
                        ]);
                    }
                    else {
                        var fileName = Data.GTIN ? "".concat(Data.GTIN, ".pdf") : 'Labels.pdf';
                        var encodedFileData = Data['PDF'];
                        var blob = b64toBlob(encodedFileData, 'application/pdf');
                        downloadFile(fileName, blob);
                        addMessage("Data received successfully! Count of labels: ".concat(Data['Count']));
                        setCounter([counterType_1, Data['Count'], undefined]);
                        emit('success', Data['Statistic']);
                    }
                }
            }
            break;
        //********   PrintSSCCLabels   **************//
        case 'PrintSSCCLabels':
            if (Data && Data['ZPL']) {
                // TODO: добавить статистику?
                print([Data['ZPL'], undefined, undefined]);
            }
            break;
        //********   ReprintAggregationLabels   **************//
        case 'ReprintAggregationLabels':
            if (payload['ZPL']) {
                print([payload['ZPL'], undefined, undefined]);
            }
            break;
        //********   ReprintLabels   **************//
        case 'ReprintLabels':
            if (Data && Data['ZPL']) {
                print([
                    Data['ZPL'],
                    function () {
                        // self.props.playSound('success');
                        addMessage('Data received!');
                        setCounter(['print', undefined, undefined]);
                    },
                    Data['Statistic'],
                ]);
            }
            break;
        //********   ConfirmDataMatrix   **************//
        case 'ConfirmDataMatrix':
            if (Data && Data['Status']) {
                // self.props.playSound('success');
                addSuccessMessage('DataMatrix confirmed!');
                setCounter(['confirm', undefined, undefined]);
                emit('success', Data['Statistic']);
                /*if (self.counter < self.gridCount) {
                    self.counter += 1;
                } else {
                    self.counter = 1;
                    self.clearCounters();
                }*/
            }
            break;
        //********   ConfirmSSCC   **************//
        case 'ConfirmSSCC':
            if (Data && Data['Status']) {
                // self.props.playSound('success');
                addSuccessMessage('SSCC confirmed!');
                emit('success', Data['Statistic']);
            }
            break;
        //********   DuplicateLabel   **************//
        case 'DuplicateLabel':
            if (Data) {
                if (Data['ZPL']) {
                    print([
                        Data['ZPL'],
                        function () {
                            // self.props.playSound('success');
                            addSuccessMessage('Reprint data received!');
                            setCounter(['print', undefined, undefined]);
                        },
                        Data['Statistic'],
                    ]);
                }
                if (Data['PDF']) {
                    var usePrint = window.confirm('Use a printer?');
                    if (usePrint) {
                        print([
                            Data['PDF'],
                            function () {
                                // self.props.playSound('success');
                                // self.emit();
                                addSuccessMessage('Reprint data received!');
                                setCounter(['print', undefined, undefined]);
                            },
                            undefined,
                        ]);
                    }
                    else {
                        var fileName = Data.GTIN ? "".concat(Data.GTIN, ".pdf") : 'Labels.pdf';
                        var encodedFileData = Data['PDF'];
                        var blob = b64toBlob(encodedFileData, 'application/pdf');
                        downloadFile(fileName, blob);
                        addSuccessMessage('Reprint data received!');
                        setCounter(['print', undefined, undefined]);
                    }
                }
                emit('success');
            }
            break;
        //********   BulkPrintLabels   **************//
        case 'BulkPrintLabels':
            if (Data === null || Data === void 0 ? void 0 : Data['ZPL']) {
                print([
                    Data['ZPL'],
                    function () {
                        playSound('success');
                    },
                    Data['Statistic'],
                ]);
            }
            break;
        case 'PrintOnSerializer':
            console.log('payload', payload);
            if (payload) {
                printSerializer(Data);
            }
            break;
        //********   ConfirmBulkPrintLabels   **************//
        case 'ConfirmBulkPrintLabels':
            if (Data) {
                playSound('success');
            }
            break;
        //********   VerificateLabels   **************//
        case 'VerificateLabels':
            if (Data === null || Data === void 0 ? void 0 : Data.ISN) {
                addSuccessMessage("Marking code ".concat(Data.ISN, " confirmed!"));
            }
            break;
        //********   Aggregations   **************//
        case 'Aggregations':
            if (Data === null || Data === void 0 ? void 0 : Data['DataMatrixData']) {
                addSuccessMessage('DataMatrix: Ok!');
                setCounter(['print', undefined, 'confirm']);
            }
            break;
        //********   PrintTestLabel   **************//
        case 'PrintTestLabel':
            if (Data === null || Data === void 0 ? void 0 : Data.ZPL) {
                emit('success', Data.ZPL, 'PrintTestLabel');
            }
            break;
        //********   AggregationAndVerification   **************//
        case 'AggregationAndVerification':
            if (Data) {
                emit('success', Data, 'AggregationAndVerification');
                if (Data.ZPL) {
                    print([
                        Data.ZPL,
                        function () {
                            // self.props.playSound('success');
                            addMessage('Data received!');
                        },
                        undefined,
                    ]);
                }
            }
            break;
        //********   Sampling   **************//
        case 'Sampling':
            if (Data) {
                emit('success', Data, 'Sampling');
            }
            break;
        default:
            break;
    }
});
sendDataToSerializerFx.use(function (data) { return __awaiter(void 0, void 0, void 0, function () {
    var GUID, res, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                GUID = data.GUID;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                debugger;
                return [4 /*yield*/, fetchFx({
                        query: "query ($GUID: String) {\n            SolmarkPrintDataList (GUID: $GUID) {\n                ... on SolmarkPrintData {\n                    ProductionDate\n                    ExpirationDate\n                    Series\n                    ShiftNumber\n                    Codes\n                }\n                ... on Error {\n                    name\n                    message\n                }\n            }\n        }",
                        variables: {
                            GUID: GUID
                        }
                    })];
            case 2:
                res = _a.sent();
                return [2 /*return*/, res];
            case 3:
                e_2 = _a.sent();
                return [2 /*return*/, Promise.reject(new Error((e_2 === null || e_2 === void 0 ? void 0 : e_2.message) || String(e_2)))];
            case 4: return [2 /*return*/];
        }
    });
}); });
sample({
    clock: sendDataToSerializer,
    source: $authorized,
    filter: function (authorized) {
        return authorized;
    },
    fn: function (_, operation) {
        return operation;
    },
    target: sendDataToSerializerFx,
});
sample({
    clock: sendDataToSerializerFx.failData,
    target: onMessageReject,
});
sample({
    clock: sendDataToSerializerFx.doneData,
    fn: function (data) {
        console.log('data from sendDataToSerializerFx', data);
        return {
            Operation: 'PrintOnSerializer',
            Data: data,
        };
    },
    target: onMessageResolve,
});
sample({
    clock: sendDataToSerializerFx.doneData,
    fn: function () {
        return {
            type: 'success',
            message: 'Data received!',
        };
    },
    target: addMessage,
});
sample({
    clock: printSerializer,
    source: $serializers,
    filter: function (serializer) {
        var list = serializer.list;
        var serializerData = Object.values(list)[0];
        debugger;
        return serializerData == undefined;
    },
    fn: function () {
        return 'Укажите сериализатор для печати!';
    },
    target: addErrorMessage,
});
sample({
    clock: printSerializer,
    source: $serializers,
    // filter(serializer) {
    //     const { list } = serializer;
    //     const serializerData: any = Object.values(list)[0]
    //     return serializerData != null;
    // },
    fn: function (serializer, payload) {
        var list = serializer.list;
        var serializerData = Object.values(list)[0];
        var ip = serializerData.ip, id = serializerData.id, name = serializerData.name;
        console.log('payload from sample', payload);
        console.log('serializers', serializer);
        console.log('serializerData', serializerData);
        return {
            ip: ip,
            id: id,
            name: name,
            payload: payload,
        };
    },
    target: printSerializerFx,
});
printSerializerFx.use(function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var ip, payload, address, headers;
    return __generator(this, function (_a) {
        ip = params.ip, payload = params.payload;
        debugger;
        if (!ip) {
            return [2 /*return*/, Promise.reject(new PrintError('Incorrect serializer name!'))];
        }
        try {
            console.log('params = payload from printSerializerFx', params);
            address = "http://127.0.0.1:9999/".concat(ip);
            headers = { 'Content-Type': 'application/json' };
            fetch(address, {
                headers: headers,
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(payload),
            });
            return [2 /*return*/, Promise.resolve()];
        }
        catch (e) {
            return [2 /*return*/, Promise.reject(new Error((e === null || e === void 0 ? void 0 : e.message) || String(e)))];
        }
        return [2 /*return*/];
    });
}); });
