import { getLazyComponent } from '@alta-m1/pages/utils';
import React from 'react';

// export const template = getLazyComponent(() => import('./label-template'));
// export const templates = getLazyComponent(() => import('./label-templates'));
// export const task = getLazyComponent(() => import('./task'));
// export const tasks = getLazyComponent(() => import('./tasks'));
// export const marking = getLazyComponent(() => import('./marking'));
// // eslint-disable-next-line camelcase
// export const marking_protocols = getLazyComponent(() => import('./marking-protocols'));
// export const sscc = getLazyComponent(() => import('./sscc'));
// export const aggregations = getLazyComponent(() => import('./aggregations'));
// export const aggregation = getLazyComponent(() => import('./aggregation'));
// export const settings = getLazyComponent(() => import('./settings'));
// export const pictures = getLazyComponent(() => import('./pictures'));
// // eslint-disable-next-line camelcase
// export const templates_editor = getLazyComponent(() => import('./template-editor'));
// // eslint-disable-next-line camelcase
// export const cancel_documents = getLazyComponent(() => import('./cancel-documents'));
// // eslint-disable-next-line camelcase
// export const cancel_document = getLazyComponent(() => import('./cancel-document'));


export const template = React.lazy(() => import('./label-template'));
export const templates = React.lazy(() => import('./label-templates'));
export const task = React.lazy(() => import('./task'));
export const tasks = React.lazy(() => import('./tasks'));
export const marking = React.lazy(() => import('./marking'));
// // eslint-disable-next-line camelcase
export const marking_protocols = React.lazy(() => import('./marking-protocols'));
export const sscc = React.lazy(() => import('./sscc'));
export const aggregations = React.lazy(() => import('./aggregations'));
export const aggregation = React.lazy(() => import('./aggregation'));
export const settings = React.lazy(() => import('./settings'));
export const pictures = React.lazy(() => import('./pictures'));
// // eslint-disable-next-line camelcase
export const templates_editor = React.lazy(() => import('./template-editor'));
// // eslint-disable-next-line camelcase
export const cancel_documents = React.lazy(() => import('./cancel-documents'));
// // eslint-disable-next-line camelcase
export const cancel_document = React.lazy(() => import('./cancel-document'));


// cancel_documents cancel_document templates